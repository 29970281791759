const twColors = require('tailwindcss/colors')

const colors = {
  'base-content': twColors.zinc[900],
  // 'primary': twColors.sky[600],
  'primary': '#0057b8',
  'primary-content': twColors.white,
  // 'secondary': twColors.cyan[500],
  'secondary': '#ffde01',
  'secondary-content': twColors.white,
  'accent': twColors.teal[500],
  'accent-content': twColors.white,
  'neutral': twColors.zinc[500],
  'neutral-content': twColors.white,
  'info': twColors.gray[200],
  'info-content': twColors.zinc[900],
  'success':twColors.teal[500],
  'success-content': twColors.white,
  'warning': twColors.orange[500],
  'warning-content': twColors.white,
  'error': twColors.rose[600],
  'error-content': twColors.white
}

const map = {
  '--map-control-content': colors['base-content'],
  '--map-active': colors['primary'],
  '--map-inactive': colors['warning'],
  '--map-static': colors['neutral']
}

const rank = {
  '--rank-1': colors['success'],
  '--rank-2': twColors.lime[500],
  '--rank-3': twColors.amber[400],
  '--rank-4': colors['warning'],
  '--rank-5': colors['error'],
  '--rank-1-content': twColors.white,
  '--rank-2-content': twColors.white,
  '--rank-3-content': twColors.white,
  '--rank-4-content': twColors.white,
  '--rank-5-content': twColors.white,
}

// const hl = {
//   '--hl-addition': colors['primary'], //	added or changed line
//   '--hl-attr': colors['primary'], //	name of an attribute with no language defined semantics (keys in JSON, setting names in .ini), also sub-attribute within another highlighted object, like XML tag
//   '--hl-attribute': colors['primary'], //	name of an attribute followed by a structured value part, like CSS properties
//   '--hl-built_in': colors['secondary'], //	built-in or library object (constant, class, function)
//   '--hl-bullet': colors['primary'], //	list item bullet
//   '--hl-char.escape': colors['primary'], //	an escape character such as \n
//   // '--hl-class': colors['primary'], //	deprecated You probably want title.class
//   '--hl-code': colors['primary'], //	code block
//   '--hl-comment': twColors.zinc[400], //	comments
//   '--hl-deletion': colors['primary'], //	deleted line
//   '--hl-diff': colors['primary'], //	
//   '--hl-doctag': colors['primary'], //	documentation markup within comments, e.g. @params
//   '--hl-emphasis': colors['primary'], //	emphasis
//   '--hl-formula': colors['primary'], // mathematical formula
//   // '--hl-function': colors['primary'], // deprecated You probably want title.function
//   '--hl-keyword': colors['error'], //	keyword in a regular Algol-style language
//   '--hl-link': colors['primary'], //	hyperlink
//   '--hl-literal': colors['success'], // special identifier for a built-in value (true, false, null, etc.)
//   '--hl-meta-keyword': colors['primary'], // a keyword inside a meta block (note this is nested, not subscoped)
//   '--hl-meta-string': colors['primary'], //	a string inside a meta block (note this is nested, not subscoped)
//   '--hl-meta.prompt': colors['primary'], //	REPL or shell prompts or similar
//   '--hl-meta': colors['primary'], //	flags, modifiers, annotations, processing instructions, preprocessor directives, etc
//   '--hl-name': colors['primary'], //	name of an XML tag, the first word in an s-expression
//   '--hl-number': colors['warning'], //	number, including units and modifiers, if any.
//   '--hl-operator': colors['primary'], //	operators: +, -, >>, |, ==
//   '--hl-params': colors['primary'], //	block of function arguments (parameters) at the place of declaration
//   '--hl-property': colors['primary'], //	object property obj.prop1.prop2.value
//   '--hl-punctuation': colors['neutral'], //	aux. punctuation that should be subtly highlighted (parentheses, brackets, etc.)
//   '--hl-quote': colors['neutral'], //	quotation or blockquote
//   '--hl-regexp': colors['primary'], //	literal regular expression
//   '--hl-section': colors['primary'], // heading of a section in a config file, heading in text markup
//   '--hl-selector-attr': colors['primary'], // [attr] selector
//   '--hl-selector-class': colors['primary'], // .class selector
//   '--hl-selector-id': colors['primary'], // #id selector
//   '--hl-selector-pseudo': colors['primary'], // :pseudo selector
//   '--hl-selector-tag': colors['primary'], //	tag selector
//   '--hl-string': colors['primary'], //	literal string, character
//   '--hl-strong': colors['primary'], //	strong emphasis
//   '--hl-subst': colors['error'], // parsed section inside a literal string
//   '--hl-symbol': colors['accent'], // symbolic constant, interned string, goto label
//   '--hl-tag': colors['primary'], // XML/HTML tag
//   '--hl-template-tag': colors['primary'], //	tag of a template language
//   '--hl-template-variable': colors['primary'], // variable in a template language
//   '--hl-title.class.inherited': colors['primary'], // name of class being inherited from, extended, etc.
//   '--hl-title.class': colors['secondary'], // name of a class (interface, trait, module, etc)
//   '--hl-title.function.invoke': colors['success'], // name of a function (when being invoked)
//   '--hl-title.function': colors['success'], // name of a function
//   '--hl-title': colors['primary'], // name of a class or a function
//   '--hl-type': colors['primary'], //	data type (in a language with syntactically significant types) (string, int, array, etc.)
//   '--hl-variable.constant': colors['primary'], // variable that is a constant value, ie MAX_FILES
//   '--hl-variable.language': colors['primary'], // variable with special meaning in a language, e.g.: this, window, super, self, etc.
//   '--hl-variable': colors['primary'] // variables
// }

module.exports = Object.freeze({
  // Themes
  light: {   
    ...colors,
    ...map,
    ...rank,
    // ...hl,                  
    'base-100': twColors.white
  },
  dark: {
    ...colors,
    ...map,
    ...rank,
    // ...hl,
    'base-100': twColors.zinc[900],
    'base-content': twColors.white
  },
  lufc: {
    ...colors,
    ...map,
    ...rank,
    // ...hl,
    'base-100': twColors.blue[800],
    'base-content': twColors.white,
    'primary': twColors.white,
    'primary-content': twColors.blue[800],
    'secondary': 'yellow',
    'secondary-content': twColors.blue[800],
    'accent': 'yellow',
    'accent-content': twColors.blue[800],
    'neutral': twColors.zinc[300],
    'neutral-content': twColors.blue[800],
    '--map-inactive': twColors.blue[800],
    '--map-active': colors['warning'],
    '--hl-string': 'yellow',
    '--hl-number': 'white',
    '--rank-3': 'yellow',
    '--rank-3-content':  twColors.blue[800]
  },
  ansi: {
    ...colors,
    ...map,
    ...rank,
    // ...hl,
    'base-100': 'black',
    'base-content': 'lime',
    'primary': 'blue',
    'secondary': 'lime',
    'secondary-content': 'black',
    'accent': 'fuchsia',
    'neutral': 'white',
    'neutral-content': 'black',
    'info': 'aqua',
    'info-content': 'black',
    'success': 'lime',
    'success-content': 'black',
    'warning': 'yellow',
    'warning-content': 'black',
    'error': 'red',
    'error-content': 'white',
    '--map-inactive': 'lime',
    '--map-active': 'fuchsia',
    '--hl-string': 'lime',
    '--hl-number': 'fuchsia'
  },
  // Color sets
  allColors: [].concat(
    Object.keys(rank),
    Object.keys(map),
    // Object.keys(hl)
  )
})